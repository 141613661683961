<template>
  <div class="anchors_box" v-if="anchorsList.length >0 && windowWidth>=1900" :style="setStyles">
    <Icon type="ios-arrow-dropup" class="icon_styles" :size="24" v-if="showAnchor" @click="changeAnchor"/>
    <Icon type="ios-arrow-dropdown" class="icon_styles" :size="24" v-else @click="changeAnchor"/>
    <ul v-if="showAnchor">
      <template v-for="item in anchorsList">
        <li class="anchors_box_item" :class="item.activeItem ? handleColor : ''" :title="item.name"
          @click="scrollToAnchor(item.name,true)">
          <div class="title">{{ item.name }}</div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "anchorNavigation",
  mixins: [Mixin],
  props: {
    // 动态设置锚点导航的位置
    setTop: {
      type: String,
      default: '48px'
    },
    setRight: {
      type: String,
      default: '70px'
    },
    // 页面头部导航默认的高度，(页面滚动时，会隐藏部分头部导航会影响锚点定位的准确性)
    headerHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      anchorsList: [],
      anchorTalg: true,
      showAnchor: true,
      scrollContainer: null,
      windowWidth: 1920,
      anchorElements: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    setStyles() {
      return {
        top: this.setTop,
        right: this.setRight
      };
    },
    handleColor() {
      return ['yms', 'supplier'].includes(this.system) ? 'ymsActiveItem' : 'distributorActiveItem';
    }
  },
  methods: {
    // 递归获取元素的所有父级标签的文本内容
    getParentParagraphText(element) {
      let parentElement = element.parentElement;
      while (parentElement) {
        // 检查当前元素的父级元素是否是 <p> 标签
        if (parentElement.tagName.toLowerCase() === 'p') {
          return parentElement.textContent.trim();
        }
        parentElement = parentElement.parentElement;
      }
      return null;  // 如果未找到包含有 anchorclass 类名的父级 <p> 标签，则返回 null
    },
    // 获取文章中的锚点数据
    getAnchorsFromContent(scrollContainer, content) {
      let v = this;
      v.anchorsList = [];
      v.$nextTick(() => {
        v.scrollContainer = scrollContainer;
        if (content) {
          v.anchorElements = content.querySelectorAll('.anchorclass');
          let newList = Array.from(v.anchorElements).map((item) => {
            return v.getParentParagraphText(item);
          });
          newList = v.uniqueFunc(newList);
          if (newList.length > 0) {
            newList.map((item, index) => {
              if (item) {
                v.anchorsList.push({
                  name: item,
                  activeItem: index === 0
                });
              }
            });
          }
        }
      });
    },
    // 切换锚点
    scrollToAnchor(text, val) {
      let v = this;
      v.anchorsList.map((ele, index) => {
        ele.activeItem = false;
        if (ele.name === text) {
          v.$set(this.anchorsList[index], 'activeItem', true);
          v.$forceUpdate();
        }
      });
      if (val) {
        v.anchorTalg = false;
        let newList = [];
        Array.from(v.anchorElements).map((item) => {
          let name = v.getParentParagraphText(item);
          if (name === text) {
            newList.push(item);
          }
        });
        const element = newList[0];
        if (element) {
          const scrollToY = element.getBoundingClientRect().top + window.pageYOffset - v.headerHeight;
          window.scrollTo({
            top: scrollToY,
            behavior: 'smooth',
          });
          /* element.scrollIntoView({behavior: 'smooth'});*/
          setTimeout(() => {
            v.anchorTalg = true;
          }, 1000);
        }
      }
    },
    // 监听页面滚动
    handleScroll() {
      if (this.anchorTalg) {
        const anchors = document.querySelectorAll('.anchorclass');
        anchors.forEach((anchor) => {
          // 计算锚点相对于滚动容器顶部的位置
          const rect = anchor.getBoundingClientRect();
          const scrollTop = this.scrollContainer.scrollTop; // 滚动容器的滚动位置
          // 判断锚点是否已经滚动到顶部
          if (rect.top + scrollTop <= 0) {
            let name = this.getParentParagraphText(anchor);
            this.scrollToAnchor(name, false);
          }
        });
      }
    },
    // 收起与展开锚点导航
    changeAnchor() {
      this.showAnchor = !this.showAnchor;
    },
    // 监听页面窗口大小
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  }
}
</script>

<style lang="less" scoped>
.anchors_box {
  padding: 0 10px;
  position: fixed;
  //top: 48px;
  /*right: 70px;*/
  background-color: #fff;
  width: 250px;
  border-left: 1px solid #D0D0D0;
  z-index: 100;

  .anchors_box_item {
    padding-bottom: 2px;
    line-height: 28px;
    font-size: 14px;
    cursor: pointer;

    .title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ymsActiveItem, .distributorActiveItem {
    color: #2D8CF0;
    position: relative;

    &:before {
      content: ' ';
      display: inline-block;
      position: absolute;
      width: 2px;
      height: 100%;
      background-color: #2D8CF0;
      left: -11px;
      top: 0;
      z-index: 10;
    }
  }

  .distributorActiveItem {
    color: #FD5425;

    &:before {
      background-color: #FD5425;
    }
  }

  .icon_styles {
    position: absolute;
    top: 0;
    left: -30px;
    cursor: pointer;
  }
}
</style>